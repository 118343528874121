<template>
	<v-card class="overflow-y-auto overflow-x-hidden" >
		<div>
			<v-card-actions v-if="!externalApi" style="background: #7d7d7d" class="mb-2">
				<v-card-title class="white--text">{{action}} задачу </v-card-title>
				<v-spacer></v-spacer>
				<v-btn v-if="showCloseButton" v-on:click="closeDialog()">Закрыть</v-btn>
				<v-btn color="red" style="color: white" v-on:click="clearForm()">
					Очистить
				</v-btn>
			</v-card-actions>

			<v-row class="ma-0 pa-0">
				<v-col :cols="externalApi ? 12 : 4">
					<v-text-field v-model="title" label="Название" class="px-3"> </v-text-field>
				</v-col>
				<v-divider vertical class="mb-4"></v-divider>

				<v-col :cols="externalApi ? 12 : 4" class="px-0">
					<v-select v-model="selectedRole" :items='rolesList' item-text="Name" item-value="@Role" label="Выберите роль" class="px-6">
					</v-select>
				</v-col>
			</v-row>

			<v-row class="ma-0 pa-0">
				<v-col :cols="externalApi ? 12 : 4">
					<v-textarea
							v-model="description"
							label="Описание"
							class="px-3 fill-height"
					>
					</v-textarea>
				</v-col>
				<v-divider vertical class="mb-4"></v-divider>
				<v-col :cols="externalApi ? 12 : 3">
					<div class="d-flex flex-column">
						<v-card-title class="px-0">Повтор</v-card-title>
						<v-radio-group v-model="selectedRepeat" @change="dropCurrentRepeatSettings">
							<v-radio
									:value="repeat"
									:label="repeat"
									v-for="repeat of repeats"
									:key="repeat"
							>
							</v-radio>
						</v-radio-group>
					</div>
				</v-col>
				<v-col :cols="externalApi ? 12 : 5" style="min-height: 472px;">
					<template v-for="component of componentList">
						<div
								:key="`component-${component}-${selectedRepeat}`"
								class="d-flex flex-row align-start"
								v-if="component === 'Время'">
							<v-card-title>
								Дата и время
							</v-card-title>
							<v-text-field
									v-model="timeInput" @input="inputChanged" single-line style="max-width: 70px;" label="Время">
							</v-text-field>
						</div>

						<div
								v-if="component === 'Календарь'"
								:key="`component-${component}`"
						>
							<v-date-picker
									locale="ru-ru"
									v-model="date"
									width="250"
									:title-date-format="selectedRepeat === 'Ежемесячно' ? (title) => {return `Каждое ${new Date(title).getDate()} число`} : null"
									:first-day-of-week="1"
							>
							</v-date-picker>
						</div>

						<v-row
								class="pa-2"
								v-if="componentWithButtons"
								:key="`component-${component}`"
						>
							<v-col
									cols="3"
									v-for="btn of buttonsList[component]"
									:key="`btn-selectable-${btn}`"
									class="pa-0"
							>
								<v-btn
										block
										class="rounded-0"
										elevation="0"
										:color="selectedDates.indexOf(btn) !== -1 ? '#656565' : ''"
										:style="{
									color: (selectedDates.indexOf(btn) !== -1 ? '#d7d7d7' : '')}"
										v-on:click="addRemoveDate(btn)"
								>{{btn}}</v-btn>
							</v-col>
						</v-row>

						<div
								v-if="component === 'Описание'"
								:key="`component-${component}`"
						>
							<v-date-picker
									locale="ru-ru"
									v-model="date"
									:selected-items-text="`Выбрано: ${date ? date.length : '0'}`"
									width="250"
									multiple
									:first-day-of-week="1"
							>
							</v-date-picker>
						</div>
					</template>
				</v-col>

			</v-row>

		</div>

		<v-btn
				block
				color="green"
				style="color: white; display: inline"
				v-on:click="
					callback({
						'@Sheduler': taskId,
						Name: title,
						Role: role ? role['@Role'] : selectedRole,
						Description: description,
						Periodicity: {
							type: selectedRepeat,
							time: timeInput,
							date: date,
							dayWeek: selectedDates
						},
					})
				"
		>
			{{action}}
		</v-btn>
	</v-card>
</template>

<script>

	export default {
		name: 'ShedulerDialog',


		data: () => {
			return {
				repeats: [
					'Разово',
					'Ежедневно',
					'Еженедельно',
					'Ежемесячно',
					'Ежеквартально',
					'Свой',
				],
				repeatComponents: {
					null: [],
					'Разово': ['Время','Календарь'],
					'Ежедневно': ['Время'],
					'Еженедельно': ['Время', 'Неделя'],
					'Ежемесячно': ['Время', 'Календарь'],
					'Ежеквартально': ['Время', 'Календарь'],
					'Свой': ['Время', 'Описание']
				},
				buttonsList: {
					'Неделя': ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"]
				},
				selectedDates: [],
				selectedRepeat: 'Разово',
				title: '',
				description: '',
				timeInput: null,
				date: null,
				repeatDescription: null,
				taskId: null,
				selectedRole: null
			}
		},
		watch: {
			showDialog(newValue) {
				if(!newValue)
					this.clearForm();
			},
			'task.id': {
				handler(newValue)
				{
					console.log('unpack task: ', newValue)
					this.unpackTask(newValue);
				},
				deep: true
			}
		},
		computed: {
			externalApi: function() {
				console.log('window width: ', window.innerWidth)
				return window.innerWidth < 300
			},
			componentList() {
				return this.repeatComponents[this.selectedRepeat];
			},
			componentWithButtons() {
				for(const btnComponentName of Object.keys(this.buttonsList))
				{
					// 1 итерация btnComponentName = 'Неделя'
					// componentList = ['Время', 'Календарь']
					// indexOf(btnComponentName) = -1
					if(this.componentList.indexOf(btnComponentName) !== -1)
						return true;
				}

				return false;
			}
		},
		mounted() {
			console.log('mounted, this task: ', this.task);
			if(this.task) {
				this.unpackTask(this.task);
			}
			console.log('mounted')
		},
		props: ['callback', 'role', 'closeDialog', 'showDialog', 'action', 'task', 'rolesList', 'showCloseButton'],
		methods: {
			unpackTask(newValue) {
				console.log('task new value: ', newValue);
				if(this.task) {
					this.taskId = this.task['@Sheduler'];
					this.title = this.task['Name'];
					this.description = this.task['Description'];

					const periodicity = this.task['Periodicity'];
					this.timeInput = periodicity['time'];
					this.selectedRepeat = periodicity['type'];
					this.date = periodicity['date'];
					this.selectedDates = periodicity['dayWeek'];
				}
			},
			dropCurrentRepeatSettings()
			{
				this.selectedDates = [];
				this.date = null;
			},
			addRemoveDate(date) {
				const dateIndex = this.selectedDates.indexOf(date);

				if(dateIndex !== -1)
					this.selectedDates.splice(dateIndex, 1);
				else
					this.selectedDates.push(date);
			},
			clearForm() {
				console.log('clear formt')
				this.title = ''
				this.description = ''
				this.timeInput = ''
				this.date = null
				this.selectedRepeat = 'Разово'
			},
			countingTime() {},
			inputChanged(e) {
				this.$nextTick(()=>{
					console.log(e);
					if (!e) {
						return;
					}
					console.log('len:', e.length);

					if (e.length === 2) {
						let parser = parseInt(e);

						console.log('parser value:', parser);

						if(parser === 0)
							return;

						console.log('parser !== 0');

						if(!parser || parser >= 24) {
							console.log('parser >= 3');

							this.timeInput = '23:';
						} else {
							this.timeInput += ':';
							console.log('parser < 3');
						}
					}

					if(e.length === 5) {
						let minutesString = e.substr(3,2);
						let minutes = parseInt(minutesString);
						console.log('minutes: ', minutes);
						if(minutes === 0)
							return;

						if(!minutes || minutes >= 60)
							this.timeInput = this.timeInput.replace(minutesString, '59')
					}

					if(e.length > 5 )
					{
						// TODO: improve
						console.log('set new value: ', e.substr(0, 5))
						this.timeInput = e.substr(0, 5);
					}
				})
			},
			filterNum() {
				if (!/^\d+$/.test(this.timeInput)) {
					this.timeInput = this.timeInput.replace(/[^\d]/g, '')
				}
			},

			inputStringTime(time) {
				// this.value = this.value
				time.replace(/ /g, '.')
				time.replace(/_/g, '-')
				time.replace(/\.+/g, '.')
				time.replace(/[^\w.-]|[a-zA-Z]|^[.-]/g, '')
			},
		},
	}
</script>

<style></style>
